import * as React from "react";
import Svg, { Path } from "react-native-svg";
const TickCircleIcon = (props) => (
  props.fill ? 
  <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d="M11.0007 1.83331C5.94982 1.83331 1.83398 5.94915 1.83398 11C1.83398 16.0508 5.94982 20.1666 11.0007 20.1666C16.0515 20.1666 20.1673 16.0508 20.1673 11C20.1673 5.94915 16.0515 1.83331 11.0007 1.83331ZM15.3823 8.89165L10.1848 14.0891C10.0559 14.2179 9.88117 14.2902 9.69898 14.2902C9.5168 14.2902 9.34206 14.2179 9.21315 14.0891L6.61898 11.495C6.49113 11.3656 6.41942 11.191 6.41942 11.0091C6.41942 10.8273 6.49113 10.6527 6.61898 10.5233C6.88482 10.2575 7.32482 10.2575 7.59065 10.5233L9.69898 12.6316L14.4107 7.91998C14.6765 7.65415 15.1165 7.65415 15.3823 7.91998C15.6482 8.18581 15.6482 8.61665 15.3823 8.89165Z" fill="#4EB826"/>
  </Svg>
  :
  <Svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M11.0002 20.1668C16.0418 20.1668 20.1668 16.0418 20.1668 11.0002C20.1668 5.9585 16.0418 1.8335 11.0002 1.8335C5.9585 1.8335 1.8335 5.9585 1.8335 11.0002C1.8335 16.0418 5.9585 20.1668 11.0002 20.1668Z"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M7.104 10.9999L9.69817 13.5941L14.8957 8.40576"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default TickCircleIcon;
