import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { Fragment, useEffect, useState } from "react";
import {
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { AppBar } from "../components";

const MultipleChoosePage = ({ route }) => {
  const { params } = route;
  console.log(params)

  
  return (
    <>
      <AppBar
        title={"Silahkan Pilih"}
        isBack
        // params={{
        //   additionalContentId: params?.additionalContentId,
        //   isScreening: params?.isScreening,
        //   refresh: new Date().getTime(),
        //   memberId: memberId
        // }}
      />
      {isLoading ? (
        <View style={styles.loading}>
          <ActivityIndicator color="#6724E2" />
        </View>
      ) : (
        <Fragment>
          <View style={styles.list}>
            <ScrollView contentContainerStyle={{ gap: 16, padding: 16 }}>
              {/* {data
                .sort((a, b) => a.orderNo - b.orderNo)
                .map((item) => (
                  <SurveyDetailCard
                    key={item.rowId}
                    data={item}
                    answer={answer}
                    handleAnswer={setAnswer}
                  />
                ))} */}
                <Text>
                  this is me
                </Text>
            </ScrollView>
          </View>
          <View style={styles.footer}>
            <View style={styles.bar}>
              <View
                style={[
                  styles.totalBar,
                  {
                    width: `${Math.ceil(
                      (Object.keys(answer).length / data?.length) * 100
                    )}%`
                  }
                ]}
              />
            </View>
            <View style={styles.footerContent}>
              <View>
                <Text style={styles.statusTx}>Status Pertanyaan</Text>
                <Text
                  style={[
                    styles.statusTx,
                    {
                      marginTop: 4,
                      fontWeight: 600,
                      fontFamily: "Archivo-SemiBold"
                    }
                  ]}
                >
                  terjawab
                </Text>
              </View>
              <TouchableOpacity
                style={styles.button}
                onPress={() => {
                  console.log("test")
                }}
              >
                <Text style={styles.buttonTx}>Kirim Jawaban</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Fragment>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  list: {
    height: "100%",
    flex: 1,
    marginTop: 1,
    paddingBottom: 6,
    backgroundColor: "#fff"
  },
  footer: {
    // position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "#fff"
  },
  bar: {
    width: "100%",
    height: 4,
    backgroundColor: "#EEEAFF"
  },
  totalBar: {
    height: 4,
    backgroundColor: "#6724E2"
  },
  statusTx: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12
  },
  footerContent: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  button: {
    borderRadius: 10,
    paddingVertical: 12,
    paddingHorizontal: 40,
    backgroundColor: "#6724E2"
  },
  buttonTx: {
    fontFamily: "Archivo-Bold",
    fontWeight: 700,
    color: "#fff",
    fontSize: 12
  },
  loading: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#EEEAFF"
  }
});

export default MultipleChoosePage;
