import React, { useEffect, useState } from "react";
import { Pressable, ScrollView, Text, View } from "react-native";
import Modal from "react-native-modal";
import {
  ChevronDown,
  ChevronRight,
  CloseCircleIcon,
  SearchBoldIcon,
} from "../assets/icons";
import EmptyState from "./EmptyState";

function ModalDate(props) {
  const { onChange, value, placeholder } = props;
  const [visible, setVisible] = useState("");
  const [tempValue, setTempValue] = useState({
    "tanggal": "tanggal",
    "bulan": "bulan",
    "tahun": "tahun"
  });
  const [tempValueString, setTempValueString] = useState("");
  const dataSourceValue = {
    "tanggal" : Array.from({ length: 31 },
      (_, i) => 0 + 1 + i),
    "bulan": ["Januari","Februari","Maret","April","Mei","Juni","Juli","Agustus","September","Oktober","November","Desember"],
    "tahun": [new Date().getFullYear()]
  }

  useEffect(() => {
    if (!value) {
      return;
    }

    const splittedValue = value.split(" ");
    setTempValue({
      tanggal: splittedValue[0],
      bulan: splittedValue[1],
      tahun: splittedValue[2],
    });

    setTempValueString(JSON.stringify({
      tanggal: splittedValue[0],
      bulan: splittedValue[1],
      tahun: splittedValue[2],
    }));
  }, [value]);

  useEffect(() => {
    if (tempValueString === "") {
      return;
    }

    if (tempValue.tanggal != "tanggal" && tempValue.bulan != "bulan" && tempValue.tahun != "tahun") {
      onChange(`${tempValue.tanggal} ${tempValue.bulan} ${tempValue.tahun}`)
    }
  }, [tempValueString]);

  return (
    <View 
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: 10
      }}
    >
      {
        ["tanggal", "bulan", "tahun"].map((i, index) => {
          return <Pressable
            key={"press"+index}
            style={{
              borderRadius: 10,
              borderWidth: 1,
              borderColor: "#eaeaea",
              borderStyle: "solid",
              backgroundColor: "#FFF",
              flexDirection: "row",
              alignItems: "center",
              padding: 12,
              justifyContent: "space-between",
              flex: 1
            }}
            onPress={() => {
              setVisible(i);
            }}
          >
            <Text
              style={{
                color: value ? "#000" : "#999",
                fontFamily: "Archivo-Regular",
                fontSize: 12,
                fontStyle: "normal",
                fontWeight: 500,
                textTransform: "capitalize"
              }}
            >
              {tempValue[i] ?? placeholder ?? i}
            </Text>
            <ChevronDown />
          </Pressable>
        })
      }
      <Modal
        isVisible={visible}
        onBackdropPress={() => {
          setVisible("");
        }}
      >
        <View
          style={{
            width: "100%",
            backgroundColor: "#FFFFFF",
            borderRadius: 10,
			      minHeight: 300,
          }}
        >
          <View
            style={{
              paddingTop: 16,
              paddingBottom: 16,
              paddingLeft: 12,
              paddingRight: 12,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text
              style={{
                color: "#000",
                fontFamily: "Archivo-Bold",
                fontSize: 12,
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: 14,
              }}
            >
              Pilih Jawaban
            </Text>
            <Pressable
              onPress={() => {
                setVisible("");
              }}
            >
              <CloseCircleIcon />
            </Pressable>
          </View>
          {
            <ScrollView style={{maxHeight: 300, padding: 16}}>
              {dataSourceValue[visible]?.length > 0 ? (
                dataSourceValue[visible].map((p, i) => {
                  return (
                    <Pressable
                      onPress={() => {
                        // onChange(p);
                        setTempValue({
                          ...tempValue,
                          [visible]: p
                        });
                        setTempValueString(JSON.stringify(tempValue));
                        setVisible("");
                      }}
                    >
                      <View
                        style={{
                          paddingTop: 16,
                          paddingBottom: 16,
                          paddingLeft: 12,
                          paddingRight: 12,
                          borderBottomWidth:
                            i === dataSourceValue[visible].length - 1 ? 0 : 1,
                          borderBottomColor:
                            i === dataSourceValue[visible].length - 1 ? "#fff" : "#EAEAEA",
                          borderStyle: "solid",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginBottom: i === dataSourceValue[visible].length - 1 ? 20: 0
                        }}
                      >
                        <Text
                          style={{
                            color: "#000",
                            fontFamily: "Archivo-Regular",
                            fontSize: 12,
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: 14,
                          }}
                        >
                          {p}
                        </Text>
                        <ChevronRight />
                      </View>
                    </Pressable>
                  );
                })
              ) : (
                <EmptyState
                  Icon={SearchBoldIcon}
                  title="Jawaban Tidak Ditemukan"
                  desc="Gunakan kata kunci yang lain untuk mencari jawaban"
                />
              )}
              </ScrollView>
          }
        </View>
      </Modal>
    </View>
  );
}

export default ModalDate;
