import { StyleSheet, Text, TextInput, TouchableOpacity, View } from "react-native";
import { CheckCircleIcon, RadioIcon } from "../../assets/icons";
import SurveyQuestionCard from "./SurveyQuestionCard";
import ModalMenuDropdown from "../ModalMenuDropdown";
import ModalMenuMultipleDropdown from "../ModalMenuMultipleDropdown";
import ModalMenuMultipleDropdownManual from "../ModalMenuMultipleDropdownManual";
import { useEffect, useState } from "react";
import OtherOptions from "../OtherOptions";
import ModalDate from "../ModalDate";

const SurveyDetailCard = ({ data, answer, handleAnswer, isNewPractice }) => {
  const { orderNo, title, quizType, detail} = data;
  const isSingleChoice = quizType === "single_choice";
  const detailOptions = JSON.parse(detail)?.options;
  const [isOther, setIsOther] = useState(false);
  const [otherAnswer, setOtherAnswer] = useState({});
  const [otherAnswerString, setOtherAnswerString] = useState("");
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (!otherAnswer[data.rowId] || !isOther) {
      return;
    }
    let tempAnser = [];
    if (otherAnswer[data.rowId]['cat']) {
      tempAnser.push(otherAnswer[data.rowId]['cat']);
    }
    if (otherAnswer[data.rowId]['subcat']) {
      tempAnser.push(otherAnswer[data.rowId]['subcat']);
    }
    if (otherAnswer[data.rowId]['value']) {
      tempAnser.push(otherAnswer[data.rowId]['value']);
    }
    if (tempAnser.length < 3) {
      let newAnswer = answer;
      delete newAnswer[data.rowId];
      return handleAnswer({ ...newAnswer });
    }
    handleAnswer({ ...answer, [data.rowId]: `${tempAnser.join(" - ")}` });
  }, [otherAnswerString]);

  return (
    <SurveyQuestionCard number={orderNo} type={quizType} question={title}>
      {quizType === "free_text" || quizType === "short_text" ? (
        <TextInput
          multiline={quizType === "free_text" ? true : false}
          placeholder="Ketik jawaban di sini"
          style={[styles.textInput, styles.optionTx, {height: quizType === "free_text" ? 100 : 48}]}
          placeholderTextColor="#999"
          value={answer[data.rowId]}
          onChangeText={(val) => {
            if (val === "") {
              let newAnswer = answer;
              delete newAnswer[data.rowId];
              return handleAnswer({ ...newAnswer });
            }
            handleAnswer({
              ...answer,
              [data.rowId]: val,
            });
          }}
        />
      ) : (quizType === "data_source" && (isNewPractice === 0 || !isNewPractice)) ? (
        <ModalMenuDropdown
          options={detailOptions}
          isSearch
          value={answer?.[data.rowId]}
          quizType={quizType}
          data={data}
          onChange={(value) => {
            handleAnswer({ ...answer, [data.rowId]: value });
          }}
        />
      )  : (quizType === "date") ? (
        <ModalDate
          value={answer?.[data.rowId]}
          quizType={quizType}
          data={data}
          onChange={(value) => {
            handleAnswer({ ...answer, [data.rowId]: value });
          }}
        />
      ) : ((quizType === "data_source_multiple" || quizType === "data_source") && isNewPractice === 1) ? (
          <ModalMenuMultipleDropdown
            options={detailOptions}
            value={answer?.[data.rowId]}
            quizType={quizType}
            data={data}
            isNewPractice={isNewPractice}
            onChange={(value) => {
              if (value === "") {
                let newAnswer = answer;
                delete newAnswer[data.rowId];
                return handleAnswer({ ...newAnswer });
              }
              handleAnswer({ ...answer, [data.rowId]: value });
            }}
          />
      ) : (quizType === "data_source_multiple_manual" && isNewPractice === 1) ? (
        <View>
          <ModalMenuMultipleDropdownManual
            options={detailOptions}
            value={answer?.[data.rowId]}
            quizType={quizType}
            data={data}
            isNewPractice={isNewPractice}
            otherAnswer={otherAnswer}
            onChange={(value) => {
              if (value === "") {
                let newAnswer = answer;
                delete newAnswer[data.rowId];
                return handleAnswer({ ...newAnswer });
              }
              setIsOther(false);
              if (value?.value === "Lainnya") {
                setIsOther(true);
                setOptions(value?.tempOptions);
              }
              handleAnswer({ ...answer, [data.rowId]: value?.value === "Lainnya" ? "Lainnya" : value });
            }}
          />
          {
            isOther &&
            options &&
            <View 
              style={{
                backgroundColor: "#F3F3F3",
                borderColor: "#EAEAEA",
                borderRadius: 10,
                padding: 12,
                marginTop: 10
              }}
            >
              <Text style={{fontFamily: "Archivo-Bold", fontSize: 12}}>
                Tuliskan perilaku lainnya
              </Text>
              <TextInput
                placeholder="Tulis perilaku di sini"
                style={{...styles.textInput, height: 48, backgroundColor: "#FFF", fontFamily: "Archivo-Regular", fontSize: 12, marginTop: 10}}
                onChangeText={(val) => {
                  const tempOtherAnswer = otherAnswer;
                  if (!tempOtherAnswer[data.rowId]) {
                    tempOtherAnswer[data.rowId] = {};
                  }
                  tempOtherAnswer[data.rowId]['value'] = val;

                  setOtherAnswer(tempOtherAnswer);
                  setOtherAnswerString(JSON.stringify(tempOtherAnswer));
                }}
              />
              <Text style={{fontFamily: "Archivo-Bold", fontSize: 12, marginTop: 10}}>
                Kategori perilaku lainnya
              </Text>
              <View 
                style={{
                  justifyContent: "space-between",
                  flexDirection: "row",
                  marginTop: 10,
                  flexFlow: "wrap"
                }}
              >
                {
                  Object.keys(options[0]).map((b, j) => {
                    return Object.keys(options[0][b]).map((c, k) => {
                      return <OtherOptions c={c} otherAnswer={otherAnswer} setOtherAnswer={setOtherAnswer} setOtherAnswerString={setOtherAnswerString} data={data}/>
                    });
                  })
                }
              </View>
              <Text style={{fontFamily: "Archivo-Bold", fontSize: 12, marginTop: 10}}>
                Jenis Perilaku Lainnya
              </Text>
              <View 
                style={{
                  justifyContent: "space-between",
                  flexDirection: "row",
                  marginTop: 10
                }}
              >
                {
                  options.map((a, i) => {
                    // get key
                    return Object.keys(a).map((b, j) => {
                      return <TouchableOpacity
                      style={{...styles.option, width: "48%", backgroundColor: "#FFF", paddingVertical: 8, paddingHorizontal: 12}}
                      onPress={() => {
                        const tempOtherAnswer = otherAnswer;
                        if (!tempOtherAnswer[data.rowId]) {
                          tempOtherAnswer[data.rowId] = {};
                        }
                        tempOtherAnswer[data.rowId]['cat'] = b;
                        setOtherAnswer(tempOtherAnswer);
                        setOtherAnswerString(JSON.stringify(tempOtherAnswer));
                      }}
                    >
                      <RadioIcon
                        isSelected={otherAnswer[data.rowId]?.['cat'] === b}
                        style={{ flexShrink: 0 }}
                      />
                      <Text style={[styles.optionTx, { maxWidth: "90%", textTransform: "capitalize" }]}>{b}</Text>
                    </TouchableOpacity>;
                    });
                  })
                }
              </View>
            </View>
          }
        </View>
      ) : (
        Array.isArray(detailOptions) &&
        JSON.parse(detail)?.options?.map((opt, id) => (
          <TouchableOpacity
            key={id}
            style={styles.option}
            onPress={() => {
              let value;
              if (isSingleChoice) {
                value = opt;
              } else if (Array.isArray(answer?.[data.rowId])) {
                const multiple = answer?.[data.rowId];
                const idx = multiple.findIndex((i) => i === opt);

                if (idx > -1) {
                  const f = multiple.filter((i) => i !== opt);
                  value = f;
                } else {
                  value = [...multiple, opt];
                }
              } else {
                value = [opt];
              }

              if (value.length === 0) {
                let newAnswer = answer;
                delete newAnswer[data.rowId];
                return handleAnswer({ ...newAnswer });
              }

              handleAnswer({ ...answer, [data.rowId]: value });
            }}
          >
            {isSingleChoice ? (
              <RadioIcon
                isSelected={opt === answer?.[data.rowId]}
                style={{ flexShrink: 0 }}
              />
            ) : (
              <CheckCircleIcon
                isSelected={
                  Array.isArray(answer?.[data.rowId]) &&
                  answer?.[data.rowId].includes(opt)
                }
                style={{ flexShrink: 0 }}
              />
            )}
            <Text style={[styles.optionTx, { maxWidth: "90%" }]}>{opt}</Text>
          </TouchableOpacity>
        ))
      )}
    </SurveyQuestionCard>
  );
};

const styles = StyleSheet.create({
  option: {
    width: "100%",
    minHeight: 46,
    borderWidth: 1,
    borderRadius: 10,
    padding: 12,
    position: "relative",
    borderColor: "#EAEAEA",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  optionTx: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontSize: 12,
    lineHeight: 14,
    zIndex: 1,
  },
  textInput: {
    height: 100,
    padding: 12,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#eaeaea",
    textAlignVertical: "top",
  },
});

export default SurveyDetailCard;
