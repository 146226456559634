import React, { Fragment, useEffect, useState } from "react";
import { Pressable, ScrollView, StyleSheet, Text, View } from "react-native";
import Modal from "react-native-modal";
import {
  AddCircleIcon,
  ArrowLeft,
  ChevronDown,
  RadioIcon,
  SearchBoldIcon,
  TickCircleIcon,
} from "../assets/icons";
import EmptyState from "./EmptyState";
import { getOptions } from "../services/survey";
import { TouchableOpacity } from "react-native";

function OtherOptions(props) {
  const {otherAnswer, setOtherAnswer, c, data, setOtherAnswerString} = props;

  return (
    <TouchableOpacity
                        style={{...styles.option, width: "48%", backgroundColor: "#FFF", paddingVertical: 8, paddingHorizontal: 12}}
                        onPress={() => {
                          const tempOtherAnswer = otherAnswer;
                          if (!tempOtherAnswer[data.rowId]) {
                            tempOtherAnswer[data.rowId] = {};
                          }
                          tempOtherAnswer[data.rowId].subcat = c;
                          setOtherAnswer(tempOtherAnswer);
                          setOtherAnswerString(JSON.stringify(tempOtherAnswer));
                        }}
                      >
                        <RadioIcon
                          isSelected={otherAnswer[data.rowId]?.['subcat'] === c}
                          style={{ flexShrink: 0 }}
                        />
                        <Text style={[styles.optionTx, { maxWidth: "90%", textTransform: "capitalize" }]}>{c}</Text>
                      </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  option: {
    width: "100%",
    minHeight: 46,
    borderWidth: 1,
    borderRadius: 10,
    padding: 12,
    position: "relative",
    borderColor: "#EAEAEA",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  optionTx: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontSize: 12,
    lineHeight: 14,
    zIndex: 1,
  },
  textInput: {
    height: 100,
    padding: 12,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#eaeaea",
    textAlignVertical: "top",
  },
});


export default OtherOptions;
